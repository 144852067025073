// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Divider, List, Stack, Typography } from '@mui/material';

// assets
import axiosServices from 'utils/axios';
import { getAllNotifcations } from 'store/slices/notifications';
import { Link, useNavigate } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications, dispatch, handleToggle }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const readNotification = (item) => {
        navigate(`/${item?.path}`);
        handleToggle();
        if (item.readStatus) return;
        axiosServices.patch(`/api/notifications/read-notification/${item?.id}`).then((res) => {
            if (res?.data?.status === 'success') {
                dispatch(getAllNotifcations());
            }
        });
    };

    const handleDeleteNotification = (id) => {
        axiosServices.delete(`/api/notifications/${id}`).then((res) => {
            if (res?.data?.status === 'success') {
                dispatch(getAllNotifcations());
            }
        });
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {notifications?.length > 0 ? (
                    notifications?.map((item, key) => (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={1}
                            py={1}
                            px={2}
                            key={key}
                            bgcolor={!item?.readStatus && '#f3f3f3'}
                            sx={{ borderBottom: '1px solid #edebeb' }}
                        >
                            <Stack
                                direction="column"
                                sx={{ borderBottom: '1px solid #edebeb', cursor: 'pointer' }}
                                onClick={() => readNotification(item)}
                            >
                                <Typography variant="h5">{item?.title}</Typography>
                                <Typography fontSize={14} mt={1}>
                                    {item?.subTitle}
                                </Typography>
                                <Typography variant="caption">
                                    ({moment(item?.createdAt).format('DD-MM-YYYY')}&nbsp;
                                    {moment(item?.createdAt).format('hh:mm A')})
                                </Typography>
                            </Stack>
                            <Button onClick={() => handleDeleteNotification(item?.id)} size="small">
                                {/* <DeleteOutlineIcon sx={{ color: '	#ff3333' }} /> */}
                                <CloseIcon sx={{ color: '	#ff3333' }} />
                            </Button>
                        </Stack>
                    ))
                ) : (
                    <Typography px={2} py={1}>
                        No Notification Found!
                    </Typography>
                )}
            </Box>
            <Divider />
        </List>
    );
};

export default NotificationList;
