import { getToken } from 'firebase/messaging';
import { FIREBASE_VAPID_KEY } from '../config';
import messaging from './firebaseConfig';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axios';
import { updateFcmToken } from 'store/slices/fcmToken';
import { useDispatch } from 'react-redux';

const useRequestForToken = () => {
    const [fcmToken, setFcmToken] = useState(null);
    const dispatch = useDispatch();

    const sendTokenToServer = async (token) => {
        if (!token) return;
        try {
            const response = await axiosServices.post('/api/fcm-token', { fcmToken: token });
        } catch (error) {
            console.error('Error sending token to server: ', error);
        }
    };
    useEffect(async () => {
        await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
            .then((currentToken) => {
                if (currentToken) {
                    setFcmToken(currentToken);
                    sendTokenToServer(currentToken);
                    dispatch(updateFcmToken(currentToken));
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                setFcmToken(null);
                dispatch(updateFcmToken(null));
            });
    }, [fcmToken]);
    return fcmToken;
};

export default useRequestForToken;
