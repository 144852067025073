// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconCalculator,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconBell,
    IconMail,
    IconUser,
    IconBuilding,
    IconSubtask,
    IconClock,
    IconCalendarMinus,
    IconFiles,
    IconBrandMastercard,
    IconTruck,
    IconList,
    IconCertificate,
    IconCash,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconUserSearch,
    IconCalendar,
    IconBooks,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconSpeakerphone,
    IconNotebook,
    IconFileInfo,
    IconTruckDelivery,
    IconStack3,
    IconWall,
    IconBrandSentry,
    IconClipboardText,
    IconAsset,
    IconShovel,
    IconBuildingFortress,
    IconHelp,
    IconTools,
    IconBrandAsana,
    IconMist,
    IconBrandUnity,
    IconSitemap,
    IconRecharging,
    IconHistory,
    IconTrowel,
    IconGenderAgender,
    IconCurrency,
    IconBrandProducthunt
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconCalculator,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconClipboardText,
    IconAsset,
    IconShovel,
    IconBuildingFortress,
    IconHelp,
    IconTools,
    IconBrandAsana,
    IconMail,
    IconUser,
    IconBuilding,
    IconSubtask,
    IconClock,
    IconCalendarMinus,
    IconFiles,
    IconBrandMastercard,
    IconTruck,
    IconBrandUnity,
    IconList,
    IconCertificate,
    IconCash,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconUserSearch,
    IconCalendar,
    IconBooks,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconSpeakerphone,
    IconNotebook,
    IconFileInfo,
    IconTruckDelivery,
    IconWall,
    IconBrandSentry,
    IconMist,
    IconUsers,
    IconSitemap,
    IconRecharging,
    IconStack3,
    IconHistory,
    IconTrowel,
    IconGenderAgender,
    IconCurrency,
    IconBrandProducthunt
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            role: ['Admin', 'Staff'],
            url: '/dashboard'
        },
        {
            id: 'calendar',
            role: ['Admin', 'Staff'],
            title: <FormattedMessage id="calendar" />,
            type: 'item',
            icon: icons.IconCalendar,
            url: '/calendar'
        },
        {
            id: 'staff',
            role: ['Admin', 'Staff'],
            title: <FormattedMessage id="staff" />,
            icon: icons.IconUsers,
            url: '/staff',
            type: 'collapse',
            // permissionID: 'Staff-all',
            children: [
                {
                    id: 'staff-list',
                    title: <FormattedMessage id="staff-list" />,
                    type: 'item',
                    icon: icons.IconUser,
                    role: ['Admin'],
                    url: '/staff',
                    permissionID: 'Staff-read'
                },
                {
                    id: 'project-history',
                    title: <FormattedMessage id="project-history" />,
                    type: 'item',
                    role: ['Admin', 'Staff'],
                    icon: icons.IconBuildingFortress,
                    url: '/staff/history/projects-history'
                },
                {
                    id: 'asset-requests',
                    title: <FormattedMessage id="asset-requests" />,
                    type: 'item',
                    icon: icons.IconHelp,
                    url: '/assets-request',
                    role: ['Admin', 'Staff'],
                    permissionID: 'Staff-read'
                },
                {
                    id: 'assigned-assets',
                    title: <FormattedMessage id="assigned-assets" />,
                    type: 'item',
                    icon: icons.IconTools,
                    url: '/assigned-assets',
                    role: ['Staff'],
                    permissionID: 'Staff-read'
                },
                {
                    id: 'assigned-projects',
                    title: <FormattedMessage id="assigned-projects" />,
                    type: 'item',
                    icon: icons.IconBrandAsana,
                    url: '/assigned-projects',
                    role: ['Staff'],
                    permissionID: 'Staff-read'
                }
            ]
        },
        {
            id: 'hrm',
            title: <FormattedMessage id="hrm" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            role: ['Admin', 'Staff'],
            permissionID: 'Attendance-read',
            children: [
                {
                    id: 'attendance',
                    title: <FormattedMessage id="attendance" />,
                    type: 'item',
                    icon: icons.IconCalendarEvent,

                    url: '/hrm/attendance',
                    permissionID: 'Attendance-read'
                },
                {
                    id: 'leaves',
                    title: <FormattedMessage id="leaves" />,
                    type: 'item',
                    icon: icons.IconCalendarStats,

                    url: '/hrm/leaves',
                    permissionID: 'Leave-read'
                },
                {
                    id: 'announcement',
                    title: <FormattedMessage id="announcement" />,
                    type: 'item',
                    icon: icons.IconSpeakerphone,

                    url: '/hrm/announcements',
                    permissionID: 'Announcement-read'
                }
            ]
        },
        {
            id: 'projects',
            title: <FormattedMessage id="projects" />,
            type: 'collapse',
            icon: icons.IconBrandProducthunt,
            role: ['Admin'],
            permissionID: 'Project-read',
            children: [
                {
                    id: 'project-list',
                    title: <FormattedMessage id="project-list" />,
                    type: 'item',
                    icon: icons.IconFiles,
                    role: ['Admin', 'Staff'],
                    url: '/projects',
                    permissionID: 'Project-read'
                },
                {
                    id: 'sites',
                    title: <FormattedMessage id="sites" />,
                    type: 'item',
                    icon: icons.IconSitemap,
                    role: ['Admin', 'Staff'],
                    url: '/sites',
                    permissionID: 'Site-read'
                },
                {
                    id: 'project-status',
                    title: <FormattedMessage id="project-status" />,
                    type: 'item',
                    icon: icons.IconRecharging,
                    role: ['Admin'],
                    url: '/project-status',
                    permissionID: 'ProjectStatus-read'
                }
            ]
        },

        {
            id: 'supplier',
            title: <FormattedMessage id="supplier" />,
            type: 'item',
            icon: icons.IconTruck,
            role: ['Admin'],
            url: '/supplier',
            permissionID: 'Supplier-read'
        },
        {
            id: 'purchase',
            title: <FormattedMessage id="purchase" />,
            type: 'collapse',
            icon: icons.IconBrandMastercard,
            role: ['Admin', 'Staff'],
            permissionID: 'QuotationRequest-read',
            children: [
                {
                    id: 'quotation-request',
                    title: <FormattedMessage id="quotation-request" />,
                    type: 'item',
                    icon: icons.IconTruckDelivery,
                    role: ['Admin'],
                    url: '/quotation-request',
                    permissionID: 'QuotationRequest-read'
                },
                {
                    id: 'quotations',
                    title: <FormattedMessage id="quotations" />,
                    type: 'item',
                    icon: icons.IconNotebook,
                    role: ['Admin'],
                    url: '/quotations',
                    permissionID: 'Quotation-read'
                },
                {
                    id: 'purchase-orders',
                    title: <FormattedMessage id="purchase-orders" />,
                    type: 'item',
                    icon: icons.IconTruckDelivery,
                    role: ['Admin'],
                    url: '/purchase-order',
                    permissionID: 'PurchaseOrder-read'
                }
            ]
        },

        {
            id: 'stock',
            title: <FormattedMessage id="stock" />,
            type: 'collapse',
            icon: icons.IconStack3,
            role: ['Admin', 'Staff'],
            permissionID: 'Inventory-read',
            children: [
                {
                    id: 'material',
                    title: <FormattedMessage id="material" />,
                    type: 'collapse',
                    icon: icons.IconBrandSentry,
                    role: ['Admin', 'Staff'],

                    children: [
                        {
                            id: 'material-item',
                            title: <FormattedMessage id="material-item" />,
                            type: 'item',
                            icon: icons.IconBrandSentry,
                            permissionID: 'MaterialItem-read',
                            url: '/stock/material'
                        },
                        {
                            id: 'inventory',
                            title: <FormattedMessage id="inventory" />,
                            type: 'item',
                            icon: icons.IconHistory,
                            url: '/stock/inventory',
                            permissionID: 'MaterialItemInventory-read',
                            role: ['Admin']
                        }
                    ]
                },

                {
                    id: 'assets',
                    title: <FormattedMessage id="assets" />,
                    type: 'collapse',
                    icon: icons.IconShovel,
                    role: ['Admin', 'Staff'],
                    children: [
                        {
                            id: 'assets-item',
                            title: <FormattedMessage id="assets-item" />,
                            type: 'item',
                            icon: icons.IconAsset,
                            permissionID: 'AssetItem-read',
                            url: '/stock/assets/assets-items'
                        },
                        {
                            id: 'inventory',
                            title: <FormattedMessage id="inventory" />,
                            type: 'item',
                            icon: icons.IconHistory,
                            permissionID: 'AssetItemInventory-read',
                            url: '/stock/assets/assets-inventory'
                        }

                        // {
                        //     id: 'history',
                        //     title: <FormattedMessage id="history" />,
                        //     type: 'item',
                        //     icon: icons.IconClipboardText,
                        //     url: '/stock/assets/assets-history'
                        // }
                    ]
                }
            ]
        },
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            icon: icons.IconFileAnalytics,
            role: ['Admin'],
            permissionID: 'Report-read',
            url: '/reports'
        },
        {
            id: 'cost-estimation',
            title: <FormattedMessage id="cost-estimation" />,
            type: 'item',
            icon: icons.IconCalculator,
            role: ['Admin'],
            permissionID: 'Report-read',
            url: '/cost-estimation'
        },
        // {
        //     id: 'support-tickets',
        //     title: <FormattedMessage id="support-tickets" />,
        //     type: 'item',
        //     icon: icons.IconTicket,
        //     url: '/support-tickets'
        // },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            role: ['Admin'],
            icon: icons.IconSettings,
            permissionID: 'Setting-read',
            children: [
                {
                    id: 'material-management',
                    title: <FormattedMessage id="item-category-item-type" />,
                    type: 'collapse',
                    icon: icons.IconWall,
                    children: [
                        {
                            id: 'item-category',
                            title: <FormattedMessage id="item-category" />,
                            type: 'item',
                            icon: icons.IconMist,
                            url: '/setting/item-category'
                        },
                        {
                            id: 'item-unit',
                            title: <FormattedMessage id="item-unit" />,
                            type: 'item',
                            icon: icons.IconBrandUnity,
                            url: '/setting/item-unit'
                        }
                    ]
                },
                {
                    id: 'roles-departments',
                    title: <FormattedMessage id="roles-departments" />,
                    type: 'collapse',
                    icon: icons.IconSubtask,
                    children: [
                        {
                            id: 'roles',
                            title: <FormattedMessage id="roles" />,
                            type: 'item',
                            icon: icons.IconUser,
                            url: '/setting/roles',
                            permissionID: 'Role-read'
                        },
                        {
                            id: 'departments',
                            title: <FormattedMessage id="departments" />,
                            type: 'item',
                            icon: icons.IconBuilding,
                            url: '/setting/departments'
                        },
                        {
                            id: 'documents-types',
                            title: <FormattedMessage id="documents-types" />,
                            type: 'item',
                            icon: icons.IconBooks,
                            url: '/setting/documentsTypes'
                        }
                    ]
                },
                {
                    id: 'places',
                    title: <FormattedMessage id="places" />,
                    type: 'collapse',
                    icon: icons.IconMapPin,
                    children: [
                        {
                            id: 'countries',
                            title: <FormattedMessage id="countries" />,
                            type: 'item',
                            icon: icons.IconWorld,
                            url: '/setting/countries',
                            permissionID: 'Country-read'
                        },
                        {
                            id: 'states',
                            title: <FormattedMessage id="states" />,
                            type: 'item',
                            icon: icons.IconBuildingBank,
                            url: '/setting/states',
                            permissionID: 'State-read'
                        },
                        {
                            id: 'city',
                            title: <FormattedMessage id="city" />,
                            type: 'item',
                            icon: icons.IconBuildingSkyscraper,
                            url: '/setting/cities',
                            permissionID: 'City-read'
                        }
                    ]
                },
                {
                    id: 'staff-management',
                    title: <FormattedMessage id="staff-management" />,
                    type: 'collapse',
                    icon: icons.IconUsers,
                    children: [
                        {
                            id: 'shift',
                            title: <FormattedMessage id="shift" />,
                            type: 'item',
                            icon: icons.IconClock,
                            url: '/setting/shift'
                        },
                        {
                            id: 'leave-type',
                            title: <FormattedMessage id="leave-type" />,
                            type: 'item',
                            icon: icons.IconCalendarMinus,
                            url: '/setting/leave-type'
                        }
                    ]
                },
                {
                    id: 'general',
                    title: <FormattedMessage id="general" />,
                    type: 'collapse',
                    icon: icons.IconSubtask,
                    children: [
                        {
                            id: 'company-details',
                            title: <FormattedMessage id="company-details" />,
                            type: 'item',
                            icon: icons.IconGenderAgender,
                            url: '/setting/company-details',
                            permissionID: 'Company-read'
                        },
                        {
                            id: 'currency',
                            title: <FormattedMessage id="currency" />,
                            type: 'item',
                            icon: icons.IconCurrency,
                            url: '/setting/currency',
                            permissionID: 'Currency-read'
                        }
                    ]
                },
                {
                    id: 'hr-type',
                    title: <FormattedMessage id="hr-type" />,
                    type: 'item',
                    icon: icons.IconUsers,
                    url: '/setting/hr-type'
                }
            ]
        }
    ]
};

export default pages;
