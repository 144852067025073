export const BASE_PATH = '';
export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const DASHBOARD_PATH = '/dashboard';

// FIREBASE CREDENTIALS
export const FIREBASE_API_KEY = process.env.REACT_APP_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const FIREBASE_SENDER_ID = process.env.REACT_APP_MESSAGE_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
export const FIREBASE_VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
