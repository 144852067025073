import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UpdateProfile from 'views/profile/UpdateProfile';
import UpdateSecurity from 'views/profile/UpdateSecurity';
import KnowledgeBaseList from 'views/knowledge/KnowledgeBaseList';
import TranningCertificateList from 'views/tranning/TranningCertificateList';
import RoleGuard from 'utils/route-guard/RoleGuard';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// calendar routing
const Calendar = Loadable(lazy(() => import('views/calendar/Calendar')));

// Profile Routing
const AppProfile = Loadable(lazy(() => import('views/profile/Profile')));
const AppUpdatePasswordSecurity = Loadable(lazy(() => import('views/profile/UpdateSecurity')));
const AppAssignedAssets = Loadable(lazy(() => import('views/profile/staffAssignedAssets')));
const AppAssignedProjects = Loadable(lazy(() => import('views/profile/staffAssignedProjects')));

// Customer Routing
const AppCustomerList = Loadable(lazy(() => import('views/customer/CustomerList')));
const AppUpdateCustomer = Loadable(lazy(() => import('views/customer/UpdateCustomer')));
const AppViewCustomer = Loadable(lazy(() => import('views/customer/CustomerView')));

//  Project Routing
const AppProjectList = Loadable(lazy(() => import('views/project/ProjectList')));
const AppProjectDetails = Loadable(lazy(() => import('views/project/ProjectDetails')));
const AppUpdateProject = Loadable(lazy(() => import('views/project/UpdateProject')));

//  Sites Routing
const AppSiteList = Loadable(lazy(() => import('views/sites/SiteList')));
const AppSiteDetails = Loadable(lazy(() => import('views/sites/SiteDetails')));
const AppUpdateSite = Loadable(lazy(() => import('views/sites/UpdateSite')));

//  Supplier Routing
const AppSupplierList = Loadable(lazy(() => import('views/suppliers/SupplierList')));
const AppUpdateSupplier = Loadable(lazy(() => import('views/suppliers/UpdateSupplier')));
const AppSupplierView = Loadable(lazy(() => import('views/suppliers/SupplierDetail')));

// Reports Routing
const AppReports = Loadable(lazy(() => import('views/report/Reports')));

// Tickets Routing
const AppTickets = Loadable(lazy(() => import('views/support-ticket/SupportTickets')));
const AppUpdateTickets = Loadable(lazy(() => import('views/support-ticket/UpdateSupportTicket')));
const AppTicketDetail = Loadable(lazy(() => import('views/support-ticket/TicketDetail')));

// FAQs Routing
const AppFaqs = Loadable(lazy(() => import('views/faqs/Faqs')));
const AppFaqUpdate = Loadable(lazy(() => import('views/faqs/UpdateFaq')));

// KnowledgeBase Routing
const AppKnowledgeBaseList = Loadable(lazy(() => import('views/knowledge/KnowledgeBaseList')));

// AccidentReport Routing
const AppAccidentReport = Loadable(lazy(() => import('views/accident/AccidentReport')));
const AppAccidentReportListing = Loadable(lazy(() => import('views/accident/accindentReportListing')));
const AppAccidentReportDetails = Loadable(lazy(() => import('views/accident/accindentReportDetail')));

// Task Routing
const AppTaskList = Loadable(lazy(() => import('views/task/TaskList')));
// Quotation
const AppQuotation = Loadable(lazy(() => import('views/quotation/QuotationList')));
const AppCreateQuotation = Loadable(lazy(() => import('views/quotation/CreateQuotation')));
const AppQuotationDetail = Loadable(lazy(() => import('views/quotation/QuotationDetail')));

// Quotation Request
const AppQuotationRequest = Loadable(lazy(() => import('views/quotation-request/requestList')));
const AppQuataionRequestAdd = Loadable(lazy(() => import('views/quotation-request/createQuotationRequest')));
const AppRequestDetail = Loadable(lazy(() => import('views/quotation-request/requestDetail')));

// Purchase Order
const AppPurchaseOrderList = Loadable(lazy(() => import('views/purchase-order/PurchaseOrderList')));
const AppPurchaseOrderAdd = Loadable(lazy(() => import('views/purchase-order/createPurchaseOrder')));
const AppPurchaseOrderDetail = Loadable(lazy(() => import('views/purchase-order/PurchaseOrderDetail')));

// STOCK
// const AppMaterialInventoryList = Loadable(lazy(() => import('views/stock/material-inventory/newmaterialInventory')));
const AppMaterialInventoryList = Loadable(lazy(() => import('views/stock/material-inventory/inventoryList')));
const AppAddInventory = Loadable(lazy(() => import('views/stock/material-inventory/createInventory')));
const AppInventoryDetails = Loadable(lazy(() => import('views/stock/material-inventory/inventoryDetails')));
const AppAssignAssetInventory = Loadable(lazy(() => import('views/stock/assets/assets-inventory/assetsInventoryListing')));
const AppAssignAssetItemList = Loadable(lazy(() => import('views/stock/assets/assets-items/assetsItemList')));
const AppAssignAssetItemDetail = Loadable(lazy(() => import('views/stock/assets/assets-items/assetsItemDetails')));
const AppAssignAssetItemAdd = Loadable(lazy(() => import('views/stock/assets/assets-items/addAssetItem')));
const AppAssignAssetDetails = Loadable(lazy(() => import('views/stock/assets/assets-inventory/assetInventoryDetails')));
const AppAddAssetInventory = Loadable(lazy(() => import('views/stock/assets/assets-inventory/createAssetsInventory')));
const AppAssignAssetsList = Loadable(lazy(() => import('views/stock/assets/assets-history/assignAssetsListing')));
const AppAssignAssetHistoryDetails = Loadable(lazy(() => import('views/stock/assets/assets-history/assignAssetsHistoryDetails')));

// EQUIPMENTS
const AppEquipmentList = Loadable(lazy(() => import('views/stock/equipments/equipmentsList')));
const AppAddEquipment = Loadable(lazy(() => import('views/stock/equipments/createEquipment')));

// Order
const AppOrder = Loadable(lazy(() => import('views/orders/OrderList')));
const AppOrderDetail = Loadable(lazy(() => import('views/orders/OrderDetail')));

// TranningCertificate Routing
const AppTranningCertificateList = Loadable(lazy(() => import('views/tranning/TranningCertificateList')));

// Staff Routing
const AppStaffList = Loadable(lazy(() => import('views/staff/StaffList')));
const AppUpdateStaff = Loadable(lazy(() => import('views/staff/UpdateStaff')));
const AppStaffDetails = Loadable(lazy(() => import('views/staff/StaffDetails')));
const AppStaffProfile = Loadable(lazy(() => import('views/staff/StaffProfile')));
const AppStaffEmergencyContact = Loadable(lazy(() => import('views/staff/emergency-contact/emergencyContacts')));
const AppStaffAttendance = Loadable(lazy(() => import('views/staff/StaffAttendance')));
const AppStaffTraining = Loadable(lazy(() => import('views/staff/traningCertificate/traningCertificate')));
const AppStaffLeave = Loadable(lazy(() => import('views/staff/StaffLeave')));
const AppStaffApplyLeave = Loadable(lazy(() => import('views/staff/ApplyLeave')));
const AppStaffAssignProject = Loadable(lazy(() => import('views/staff/assign-projects/assignProjects')));
const AppStaffAssignAssets = Loadable(lazy(() => import('views/staff/assign-assets/assignAssets')));
const AppStaffAccountDetails = Loadable(lazy(() => import('views/staff/account/AccountDetails')));
const AppStaffUpdateAccount = Loadable(lazy(() => import('views/staff/account/UpdateAccount')));
const AppUpdateParticularUser = Loadable(lazy(() => import('views/staff/account/UpdateParticularUser')));
const AppStaffSecurity = Loadable(lazy(() => import('views/staff/UpdateSecurity')));
const AppUpdateStaffDetails = Loadable(lazy(() => import('views/staff/EditStaffDetail')));
const AppStaffWarehouseDetails = Loadable(lazy(() => import('views/staff/warehouse/WarehouseDetails')));
const AppStaffUpdateWarehouse = Loadable(lazy(() => import('views/staff/warehouse/UpdateWarehouse')));
const AppAssignProjectList = Loadable(lazy(() => import('views/staff/assign-projects-history/assignProjectsListing')));
const AppAssignProjectAdd = Loadable(lazy(() => import('views/staff/assign-projects-history/assignProjectToStaff')));
const AppAssignProjectDetail = Loadable(lazy(() => import('views/staff/assign-projects-history/assignProjectDetails')));

// =========== || HRM SETTING || ================= //

// Attendance Routing
const AppAttendanceList = Loadable(lazy(() => import('views/hrm/attendance/AttendanceList')));
const AppAddAttendance = Loadable(lazy(() => import('views/hrm/attendance/addAttendance')));
const AppAttendanceDetails = Loadable(lazy(() => import('views/hrm/attendance/attendanceDetails')));

// Leaves Routing
const AppLeaveList = Loadable(lazy(() => import('views/hrm/leave/LeaveList')));
const AppUpdateLeave = Loadable(lazy(() => import('views/hrm/leave/UpdateLeave')));

// Announcement Routing
const AppAnnouncementList = Loadable(lazy(() => import('views/hrm/announcement/AnnouncementList')));
const AppUpdateAnnouncement = Loadable(lazy(() => import('views/hrm/announcement/UpdateAnnouncement')));

// // Payroll routing
// const AppAttendanceList = Loadable(lazy(() => import('views/payroll/Attendance/AttendanceList')));
// const AppUpdateAttendanceList = Loadable(lazy(() => import('views/payroll/Attendance/UpdateAttendance')));
// const AppLeaveList = Loadable(lazy(() => import('views/payroll/leave/LeaveList')));
// const AddLeave = Loadable(lazy(() => import('views/payroll/leave/AddLeave')));
// const AppPerformanceList = Loadable(lazy(() => import('views/payroll/PerformenceList')));
// const AppHrPayrollList = Loadable(lazy(() => import('views/payroll/HrPayrollList')));

// ========= || SETTING ROUTING || ===================== //

// Department Routing
const AppDepartmentList = Loadable(lazy(() => import('views/setting/department/DepartmentList')));
const AppUpdateDepartment = Loadable(lazy(() => import('views/setting/department/UpdateDepartment')));

// Places Routing
const AppCountriesList = Loadable(lazy(() => import('views/setting/places/countries/CountryName')));
const AppUpdateCountries = Loadable(lazy(() => import('views/setting/places/countries/UpdateCountryName')));
const AppStatesList = Loadable(lazy(() => import('views/setting/places/states/StateName')));
const AppUpdateStates = Loadable(lazy(() => import('views/setting/places/states/UpdateStateName')));
const AppCitiesList = Loadable(lazy(() => import('views/setting/places/cities/CityName')));
const AppUpdateCities = Loadable(lazy(() => import('views/setting/places/cities/UpdateCityName')));

// Documents Routing
const AppDocumentsTypesList = Loadable(lazy(() => import('views/setting/documents/DocumentsTypesList')));
const AppUpdateDocumentsTypes = Loadable(lazy(() => import('views/setting/documents/UpdateDocumentsTypes')));

// Role Routing
const AppRoleList = Loadable(lazy(() => import('views/setting/role/RoleList')));
const AppUpdateRole = Loadable(lazy(() => import('views/setting/role/UpdateRole')));

// Shift Routing
const AppShiftList = Loadable(lazy(() => import('views/setting/shift/ShiftList')));
const AppUpdateShift = Loadable(lazy(() => import('views/setting/shift/UpdateShift')));

// Leave Type Routing
const AppLeaveType = Loadable(lazy(() => import('views/setting/leave-type/LeaveTypeList')));
const AppUpdateLeaveType = Loadable(lazy(() => import('views/setting/leave-type/UpdateLeaveType')));

// Material
const AppMaterial = Loadable(lazy(() => import('views/setting/materials/material/materialList')));
const AppAddMaterial = Loadable(lazy(() => import('views/setting/materials/material/addMaterial')));
const AppViewMaterialPage = Loadable(lazy(() => import('views/setting/materials/material/materialDetails')));

// Material Type
const AppItemCategoryList = Loadable(lazy(() => import('views/setting/materials/item-category/itemCategoryList')));
const AppAddItemType = Loadable(lazy(() => import('views/setting/materials/item-category/addItemCategory')));

// Material
const AppItemUnitList = Loadable(lazy(() => import('views/setting/materials/item-unit/itemUnitList')));
const AppAddItemUnit = Loadable(lazy(() => import('views/setting/materials/item-unit/addItemUnit')));

// upload compnay Details
const AppCompanyDetails = Loadable(lazy(() => import('views/setting/general/editCompanyDetails')));

// Currency
const AppCurrencyList = Loadable(lazy(() => import('views/setting/general/currency/currencyListing')));
const AppAddCurrency = Loadable(lazy(() => import('views/setting/general/currency/addCurrency')));

// HR Type
const AppHRTypeList = Loadable(lazy(() => import('views/setting/hrType/hrTypeList')));
const AppAddHRType = Loadable(lazy(() => import('views/setting/hrType/updateHrType')));

// Project Status
const AppProjectStatusList = Loadable(lazy(() => import('views/project-status/projectStatusList')));
const AppAddProjectStatus = Loadable(lazy(() => import('views/project-status/updateProjectStatus')));

// ========= || SETTING ROUTING  ENDS|| ===================== //

// COMPANY ASSETS REQUESTS
const AppAssetsRequestList = Loadable(lazy(() => import('views/assetsRequest/assetRequestListing')));
const AppAssetsRequestDetails = Loadable(lazy(() => import('views/assetsRequest/assetRequestDetail')));
const AppAddRequestForAssets = Loadable(lazy(() => import('views/assetsRequest/addRequestForAssets')));

// COST Estimation routing
const AppCostEstimation = Loadable(lazy(() => import('views/costEstimation/index')));

// Data Entry routing
const AppDailyEntry = Loadable(lazy(() => import('views/dailyEntry/dailyEntry')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/calendar',
            element: <Calendar />
        },
        {
            path: '/customers',
            element: <AppCustomerList />
        },
        {
            path: '/customers/add',
            element: <AppUpdateCustomer pageBehaviour="Add" />
        },
        {
            path: '/customers/:customerID/edit',
            element: <AppUpdateCustomer pageBehaviour="Edit" />
        },
        {
            path: '/customers/:customerID',
            element: <AppViewCustomer pageBehaviour="View" />
        },
        {
            path: '/assigned-assets',
            element: <AppAssignedAssets />
        },
        {
            path: '/assigned-projects',
            element: <AppAssignedProjects />
        },
        {
            path: '/profile',
            element: <AppProfile />,
            children: [
                {
                    path: '/profile',
                    element: <UpdateProfile />
                },

                {
                    path: '/profile/assigned-projects',
                    element: <AppAssignedProjects />
                },
                {
                    path: '/profile/assigned-assets',
                    element: <AppAssignedAssets />
                },
                {
                    path: '/profile/security',
                    element: <AppUpdatePasswordSecurity />
                }
            ]
        },
        {
            path: '/projects',
            element: (
                <RoleGuard permissionID="Project-read" userPermission={['Admin', 'Staff']}>
                    <AppProjectList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/projects/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Project-write">
                    <AppUpdateProject pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/projects/:projectID/Edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Project-write">
                    <AppUpdateProject pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/projects/:projectID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Project-read">
                    <AppProjectDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/sites',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Project-read">
                    <AppSiteList />
                </RoleGuard>
            )
        },
        {
            path: '/sites/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Project-write">
                    <AppUpdateSite pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/sites/:siteId/Edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Project-write">
                    <AppUpdateSite pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/sites/:siteId',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Project-read">
                    <AppSiteDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/project-status',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="ProjectStatus-read">
                    <AppProjectStatusList />
                </RoleGuard>
            )
        },
        {
            path: '/project-status/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="ProjectStatus-write">
                    <AppAddProjectStatus pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/project-status/:statusID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="ProjectStatus-write">
                    <AppAddProjectStatus pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/supplier',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Supplier-read">
                    <AppSupplierList />
                </RoleGuard>
            )
        },
        {
            path: '/supplier/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Supplier-write">
                    <AppUpdateSupplier pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/supplier/:supplierID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Supplier-read">
                    <AppSupplierView pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/supplier/:supplierID/Edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Supplier-write">
                    <AppUpdateSupplier pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/reports',
            element: <AppReports />
        },
        {
            path: '/quotations',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Quotation-read">
                    <AppQuotation />
                </RoleGuard>
            )
        },
        {
            path: '/quotations/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Quotation-write">
                    <AppCreateQuotation pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/quotations/:quotationId/Edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Quotation-write">
                    <AppCreateQuotation pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/quotations/:quotationId',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Quotation-read">
                    <AppQuotationDetail />
                </RoleGuard>
            )
        },
        {
            path: '/quotation-request',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="QuotationRequest-read">
                    <AppQuotationRequest />
                </RoleGuard>
            )
        },
        {
            path: '/quotation-request/add',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="QuotationRequest-write">
                    <AppQuataionRequestAdd pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/quotation-request/:quotationRequestId/edit',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="QuotationRequest-write">
                    <AppQuataionRequestAdd pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/quotation-request/:quotationRequestId',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="QuotationRequest-read">
                    <AppRequestDetail />
                </RoleGuard>
            )
        },

        {
            path: '/purchase-order',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="PurchaseOrder-read">
                    <AppPurchaseOrderList />
                </RoleGuard>
            )
        },
        {
            path: '/purchase-order/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="PurchaseOrder-write">
                    <AppPurchaseOrderAdd pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/purchase-order/:purchaseOrderId/Edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="PurchaseOrder-write">
                    <AppPurchaseOrderAdd pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/purchase-order/:purchaseOrderId',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="PurchaseOrder-read">
                    <AppPurchaseOrderDetail />
                </RoleGuard>
            )
        },
        {
            path: '/stock/inventory',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="MaterialItemInventory-read">
                    <AppMaterialInventoryList />
                </RoleGuard>
            )
        },
        {
            path: '/stock/inventory/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialItemInventory-write">
                    <AppAddInventory pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/inventory/:inventoryID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="MaterialItemInventory-read">
                    <AppInventoryDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-items',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItem-read">
                    <AppAssignAssetItemList />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-items/add',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItem-write">
                    <AppAssignAssetItemAdd pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-items/:assetID/edit',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItem-write">
                    <AppAssignAssetItemAdd pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-items/:assetID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItem-read">
                    <AppAssignAssetItemDetail pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-inventory',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItemInventory-read">
                    <AppAssignAssetInventory />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-inventory/add',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItemInventory-write">
                    <AppAddAssetInventory pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-inventory/:inventoryID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItemInventory-read">
                    <AppAssignAssetDetails />
                </RoleGuard>
            )
        },
        {
            path: '/stock/assets/assets-history',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssetItemInventory-read">
                    <AppAssignAssetsList />
                </RoleGuard>
            )
        },

        {
            path: '/stock/assets/assets-history/:historyID',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssignAssets-read">
                    <AppAssignAssetHistoryDetails />
                </RoleGuard>
            )
        },
        {
            path: '/staff/history/projects-history',
            element: (
                // <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssignProject-read">
                <AppAssignProjectList />
                // </RoleGuard>
            )
        },
        {
            path: '/staff/history/projects-history/add',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssignProject-write">
                    <AppAssignProjectAdd />
                </RoleGuard>
            )
        },
        {
            path: '/staff/history/projects-history/:historyID',
            element: (
                // <RoleGuard userPermission={['Admin', 'Staff']} permissionID="AssignProject-read">
                <AppAssignProjectDetail pageBehaviour="View" />
                // </RoleGuard>
            )
        },

        {
            path: '/stock/equipments',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="Equipment-read">
                    <AppEquipmentList />
                </RoleGuard>
            )
        },
        {
            path: '/stock/equipments/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Equipment-write">
                    <AppAddEquipment pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/equipments/:equipmentID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Equipment-write">
                    <AppAddEquipment pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/accident',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="IncidentReport-read">
                    <AppAccidentReportListing />
                </RoleGuard>
            )
        },
        {
            path: '/accident/add',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="IncidentReport-write">
                    <AppAccidentReport pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/accident/:accidentId',
            element: (
                <RoleGuard userPermission={['Admin', 'Staff']} permissionID="IncidentReport-read">
                    <AppAccidentReportDetails />
                </RoleGuard>
            )
        },
        {
            path: '/support-tickets',
            element: <AppTickets />
        },
        {
            path: '/support-tickets/Add',
            element: <AppUpdateTickets pageBehaviour="Add" />
        },
        {
            path: '/support-tickets/:ticketID/Edit',
            element: <AppUpdateTickets pageBehaviour="Edit" />
        },
        {
            path: '/support-tickets/:ticketID',
            element: <AppTicketDetail pageBehaviour="View" />
        },
        // {
        //     path: '/faqs',
        //     element: <AppFaqs />
        // },
        // {
        //     path: '/faqs/add',
        //     element: <AppFaqUpdate pageBehaviour="Add" />
        // },
        // {
        //     path: '/faqs/:faqID/edit',
        //     element: <AppFaqUpdate pageBehaviour="Edit" />
        // },
        // {
        //     path: '/knowledge-base',
        //     element: <AppKnowledgeBaseList />
        // },
        // {
        //     path: '/tasks',
        //     element: <AppTaskList />
        // },
        // {
        //     path: '/tranning-certificate',
        //     element: <AppTranningCertificateList />
        // },
        {
            path: '/staff',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin']}>
                    <AppStaffList pageBehaviour="view" />
                </RoleGuard>
            )
        },
        {
            path: '/staff/add',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin']}>
                    <AppUpdateStaff pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/staff/:staffID/edit',
            element: (
                <RoleGuard permissionID="Staff-write" userPermission={['Admin']}>
                    <AppUpdateStaff pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/staff/:staffID',
            element: (
                <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Client']}>
                    <AppStaffDetails title="Staff Details" pageBehaviour="view" />
                </RoleGuard>
            ),
            children: [
                {
                    path: '/staff/:staffID',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/emergency-contact',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin']}>
                            <AppStaffEmergencyContact pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/profile',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffProfile pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/edit-details',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateStaffDetails pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/attendance',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAttendance pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/training',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffTraining pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/leave',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffLeave pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/apply-leave',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffApplyLeave pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/account-details',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAccountDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/apply-account-details',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffUpdateAccount pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/edit-account-details',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffUpdateAccount pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/edit-particular-user-details/:userSalaryID',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateParticularUser pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/add-particular-user-details',
                    element: (
                        <RoleGuard permissionID="Staff-write" userPermission={['Admin', 'Staff']}>
                            <AppUpdateParticularUser pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse',
                    element: (
                        <RoleGuard permissionID="Warehouse-read" userPermission={['Admin']}>
                            <AppStaffWarehouseDetails pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse/add',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Add" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/warehouse/:userwarehouseID/edit',
                    element: (
                        <RoleGuard permissionID="Warehouse-write" userPermission={['Admin']}>
                            <AppStaffUpdateWarehouse pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/security',
                    element: (
                        <RoleGuard permissionID="Security-write" userPermission={['Admin', 'Staff']}>
                            <AppStaffSecurity pageBehaviour="Edit" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/assign-project',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAssignProject pageBehaviour="view" />
                        </RoleGuard>
                    )
                },
                {
                    path: '/staff/:staffID/assign-assets',
                    element: (
                        <RoleGuard permissionID="Staff-read" userPermission={['Admin', 'Staff']}>
                            <AppStaffAssignAssets pageBehaviour="view" />
                        </RoleGuard>
                    )
                }
            ]
        },
        {
            path: '/hrm/attendance',
            element: (
                <RoleGuard permissionID="Attendance-read" userPermission={['Admin', 'Staff']}>
                    <AppAttendanceList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/attendance/add',
            element: (
                <RoleGuard permissionID="Attendance-write" userPermission={['Admin']}>
                    <AppAddAttendance pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/attendance/:attendanceID',
            element: (
                <RoleGuard permissionID="Attendance-read" userPermission={['Admin']}>
                    <AppAttendanceDetails pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/attendance/:attendanceId/edit',
            element: (
                <RoleGuard permissionID="Attendance-write" userPermission={['Admin']}>
                    <AppAddAttendance pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves',
            element: (
                <RoleGuard permissionID="Leave-read" userPermission={['Admin', 'Staff']}>
                    <AppLeaveList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/add',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaves/:leaveID/edit',
            element: (
                <RoleGuard permissionID="Leave-write" userPermission={['Admin', 'Staff']}>
                    <AppUpdateLeave pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements',
            element: (
                <RoleGuard permissionID="Announcement-read" userPermission={['Admin', 'Staff']}>
                    <AppAnnouncementList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/add',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin']}>
                    <AppUpdateAnnouncement pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/announcements/:announcementID/edit',
            element: (
                <RoleGuard permissionID="Announcement-write" userPermission={['Admin']}>
                    <AppUpdateAnnouncement pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        // {
        //     path: '/payroll/attendance',
        //     element: <AppAttendanceList />
        // },
        // {
        //     path: '/payroll/attendance/add',
        //     element: <AppUpdateAttendanceList />
        // },
        // {
        //     path: '/payroll/leaves',
        //     element: <AppLeaveList />
        // },
        // {
        //     path: '/payroll/add-leaves',
        //     element: <AddLeave pageBehaviour="Add" />
        // },
        // {
        //     path: '/payroll/performence',
        //     element: <AppPerformanceList />
        // },
        // {
        //     path: '/payroll/hr-payroll',
        //     element: <AppHrPayrollList />
        // },
        // {
        //     path: '/me',
        //     element: <AppStaffDetails title="My Profile" pageBehaviour="view" />,
        //     children: [
        //         {
        //             path: '/me',
        //             element: <AppStaffProfile pageBehaviour="View" />
        //         },
        //         {
        //             path: '/me/attendance',
        //             element: <AppStaffAttendance pageBehaviour="view" />
        //         },
        //         {
        //             path: '/me/leave',
        //             element: <AppStaffLeave pageBehaviour="view" />
        //         },
        //         {
        //             path: '/me/apply-leave',
        //             element: <AddStaffApplyLeave pageBehaviour="Add" />
        //         },
        //         {
        //             path: '/me/security',
        //             element: <AppStaffSecurity pageBehaviour="Edit" />
        //         }
        //     ]
        // },
        {
            path: '/stock/material',
            element: (
                <RoleGuard permissionID="MaterialItem-read" userPermission={['Admin']}>
                    <AppMaterial />
                </RoleGuard>
            )
        },
        {
            path: '/stock/material/:materialId',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialItem-read">
                    <AppViewMaterialPage />
                </RoleGuard>
            )
        },
        {
            path: '/stock/material/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialItem-write">
                    <AppAddMaterial pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/stock/material/:materialId/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialItem-read">
                    <AppAddMaterial pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-category',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialType-read">
                    <AppItemCategoryList />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-category/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialType-write">
                    <AppAddItemType pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-category/:materialTypeId/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialType-write">
                    <AppAddItemType pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-unit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialUnit-read">
                    <AppItemUnitList />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-unit/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialUnit-write">
                    <AppAddItemUnit pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/item-unit/:materialUnitId/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="MaterialUnit-write">
                    <AppAddItemUnit pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/hr-type',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="HrType-read">
                    <AppHRTypeList />
                </RoleGuard>
            )
        },
        {
            path: '/setting/hr-type/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="HrType-write">
                    <AppAddHRType pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/hr-type/:TypeID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="HrType-write">
                    <AppAddHRType pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/setting/company-details',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Logo-write">
                    <AppCompanyDetails />
                </RoleGuard>
            )
        },
        {
            path: '/setting/currency',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Currency-read">
                    <AppCurrencyList />
                </RoleGuard>
            )
        },
        {
            path: '/setting/currency/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Currency-write">
                    <AppAddCurrency pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/currency/:currencyID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Currency-write">
                    <AppAddCurrency pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Role-read">
                    <AppRoleList />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Role-write">
                    <AppUpdateRole pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Role-write">
                    <AppUpdateRole pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/roles/:roleID/',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Role-read">
                    <AppUpdateRole pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Document-read">
                    <AppDocumentsTypesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Document-write">
                    <AppUpdateDocumentsTypes pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/documentsTypes/:documentsTypesID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Document-write">
                    <AppUpdateDocumentsTypes pageBehaviour="Edit" />
                </RoleGuard>
            )
        },

        {
            path: '/setting/departments',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Department-read">
                    <AppDepartmentList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/departments/add',
            element: (
                <RoleGuard userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Add" permissionID="Department-write" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/departments/:departmentID/edit',
            element: (
                <RoleGuard userPermission={['Admin']}>
                    <AppUpdateDepartment pageBehaviour="Edit" permissionID="Department-write" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Country-read">
                    <AppCountriesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Country-write">
                    <AppUpdateCountries pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/countries/:countryId/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Country-write">
                    <AppUpdateCountries pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="State-read">
                    <AppStatesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="State-write">
                    <AppUpdateStates pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/states/:statesID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="State-write">
                    <AppUpdateStates pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="City-read">
                    <AppCitiesList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="City-write">
                    <AppUpdateCities pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/cities/:citiesID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="City-write">
                    <AppUpdateCities pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Shift-read">
                    <AppShiftList pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Shift-write">
                    <AppUpdateShift pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/shift/:shiftID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="Shift-write">
                    <AppUpdateShift pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="LeaveType-read">
                    <AppLeaveType pageBehaviour="View" />
                </RoleGuard>
            )
        },
        {
            path: '/setting/leave-type/add',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="LeaveType-write">
                    <AppUpdateLeaveType pageBehaviour="Add" />
                </RoleGuard>
            )
        },
        {
            path: '/hrm/leaveTypes/:leaveTypeID/edit',
            element: (
                <RoleGuard userPermission={['Admin']} permissionID="LeaveType-write">
                    <AppUpdateLeaveType pageBehaviour="Edit" />
                </RoleGuard>
            )
        },
        {
            path: '/assets-request',
            element: (
                // <RoleGuard permissionID="AssetRequest-read" userPermission={['Admin']}>
                <AppAssetsRequestList pageBehaviour="View" />
                // </RoleGuard>
            )
        },
        {
            path: '/assets-request/:requestID',
            element: (
                // <RoleGuard permissionID="AssetRequest-read" userPermission={['Admin']}>
                <AppAssetsRequestDetails pageBehaviour="View" />
                // </RoleGuard>
            )
        },
        {
            path: '/assets-request/add',
            element: (
                // <RoleGuard permissionID="AssetRequest-read" userPermission={['Admin']}>
                <AppAddRequestForAssets pageBehaviour="Add" />
                // </RoleGuard>
            )
        },
        {
            path: '/cost-estimation',
            element: (
                <RoleGuard permissionID="CostEstimation-read">
                    <AppCostEstimation />
                </RoleGuard>
            )
        },
        {
            path: '/daily-entry',
            element: (
                <RoleGuard permissionID="DailyEntry-read">
                    <AppDailyEntry />
                </RoleGuard>
            )
        }
    ]
};

export default MainRoutes;
